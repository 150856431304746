.modalContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(28, 19, 48, 0.5);
  display: none;
  z-index: 200;

  &.open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.modal {
  padding: 16px;
  border-radius: 48px;
  border: 3px solid #fff;
  background: var(--kotini-green-shades-10, #e5f6f5);
  min-width: 400px;
}

.modalHeader {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: flex-end;
  flex-shrink: 0;

  > h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .spacer {
    width: 60px;
  }
}

.modalContent {
  // display: flex;
  margin-top: 32px;
}

/* Tablet Portrait Breakpoint */
@media only screen and (max-width: 768px) {
  .modalContainer {
    position: fixed;
    top: 0;
  }
}
