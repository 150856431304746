.kotini-loader-container {
  --loader-background: var(--background-0);
  --loader-main: var(--background-2);
  --loader-tick: var(--background-2);

  width: 100%;
  height: 100%;
  background: var(--loader-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
/* .kotini-loader-container > svg {
  width: 128px;
  height: 128px;
} */

.loader-logo-windows {
  fill: var(--loader-main);
  stroke-width: 0px;

  -webkit-animation: spin90 1s reverse infinite;
  -moz-animation: spin90 1s reverse infinite;
  animation: spin90 1s reverse infinite;
  transform-origin: center center;
  animation-timing-function: ease-in-out;
}

.loader-logo-tick,
.loader-logo-roof,
.loader-logo-chimney {
  fill: none;
  stroke: var(--loader-main);
  stroke-miterlimit: 10;
  stroke-width: 8px;
}
.loader-logo-tick {
  stroke: var(--loader-tick);
}

.loader-logo-outer {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  animation-timing-function: ease-in-out;
  transform-origin: center center;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(180deg);
  }

  100% {
    -moz-transform: rotate(180deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  60% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin90 {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(0deg);
  }
}
@-webkit-keyframes spin90 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
  }
}
@keyframes spin90 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
